var exports = {};

/**
 * Module exports.
 * @public
 */
exports = unpipe;
/**
 * Determine if there are Node.js pipe-like data listeners.
 * @private
 */

function hasPipeDataListeners(stream) {
  var listeners = stream.listeners("data");

  for (var i = 0; i < listeners.length; i++) {
    if (listeners[i].name === "ondata") {
      return true;
    }
  }

  return false;
}
/**
 * Unpipe a stream from all destinations.
 *
 * @param {object} stream
 * @public
 */


function unpipe(stream) {
  if (!stream) {
    throw new TypeError("argument stream is required");
  }

  if (typeof stream.unpipe === "function") {
    // new-style
    stream.unpipe();
    return;
  } // Node.js 0.8 hack


  if (!hasPipeDataListeners(stream)) {
    return;
  }

  var listener;
  var listeners = stream.listeners("close");

  for (var i = 0; i < listeners.length; i++) {
    listener = listeners[i];

    if (listener.name !== "cleanup" && listener.name !== "onclose") {
      continue;
    } // invoke the listener


    listener.call(stream);
  }
}

export default exports;